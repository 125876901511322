<template>
  <div class="terms_common">
    <h4>램 이용약관</h4>

    <h5>제1장 총칙</h5>

    <!---->
    <h6>제1조 (목적)</h6>

    <p>
      주식회사 리뷰앤메이크머니(이하 ‘회사’)가 제공하는 서비스를 이용해주셔서
      감사합니다. 본 회사는 인터넷 게시판 겸 사이버몰 ‘리뷰하고 돈벌자(이하
      ‘램’)’를 통해 다양한 서비스를 제공합니다. 이 약관의 목적은 인터넷 플랫폼
      ‘램’과 이 플랫폼을 이용하는 이용자의 권리․의무 및 책임 사항을 규정하는
      것입니다.
    </p>
    <br />

    <!---->
    <h6>제2조 (용어의 정의)</h6>

    <p>
      ① 램(RAMM): 램은 ‘Review and Make Money’의 약자이며 인터넷 게시판 겸
      사이버몰로서 가상의 영업장(https://reviewnmakemoney.com,
      https://m.reviewnmakemoney.com 등 회사가 운영하는 컴퓨터 웹사이트 및
      모바일 웹과 앱 등 모두 포함)입니다. 이 영업장을 통해 상품과 서비스, 리뷰가
      게시, 거래됩니다.
    </p>
    <p>
      ② 리뷰 놀이터(Review Playground): 리뷰 놀이터는 ‘램’ 안에 위치하며,
      상품/서비스의 리뷰가 게시되는 가상의 공간입니다. 리뷰어는 상품 및 서비스의
      리뷰를 작성해 리뷰 놀이터에 올리고 리뷰 이용자는 리뷰 놀이터에서 리뷰를
      소비합니다.
    </p>
    <p>
      ③ 램 장터(RAMM Market): 램 장터는 오픈 마켓으로서 ‘램’ 안에 위치하며,
      제휴사 또는 램 가게 입점 업체가 상품 또는 서비스 정보를 제공하는 가상의
      공간입니다. 이곳은 상품 또는 서비스 정보가 모이는 곳입니다. 이곳에서
      리뷰어는 자신이 원하는 상품이나 서비스의 ‘리뷰하기’ 버튼을 누르고 리뷰를
      작성해 리뷰 놀이터에 올릴 수 있습니다(제휴사가 제공한 상품/서비스 정보의
      경우). 또는 램 장터는 일반 소비자들이 직접 방문해 상품/서비스 정보를
      살펴보고 상품/서비스의 구매를 결정하는 가상의 공간이기도 합니다. 이곳을
      경유해 소비자들은 램 가게나 제휴사 홈페이지로 이동해 상품 및 서비스를
      구매합니다.
    </p>
    <p>
      ④ 램 가게(RAMM Store): 램 가게는 오픈 마켓인 ‘램 장터’에 위치한 가상의
      상점입니다. 이곳에서 리뷰어는 자신이 원하는 상품이나 서비스의 ‘리뷰하기’
      버튼을 누르고 리뷰를 작성해 리뷰 놀이터에 올릴 수 있습니다. 또는 램 가게는
      리뷰 이용자가 리뷰 놀이터의 리뷰를 통해 또는 램 장터나 검색을 통해
      방문하는 가상의 공간이기도 합니다. 아울러 램 가게는 판매자 회원이 입점해
      다양한 경영활동을 하는 가상의 공간이기도 합니다. 램 가게 입점 업체는
      수동적으로는 리뷰어들의 리뷰를 통해 자신들의 상품이나 서비스를 홍보하게
      되며 능동적으로는 리뷰 거래소를 통해 자신들의 상품이나 서비스를 홍보할
      수도 있습니다.
    </p>
    <p>
      ⑤ 리뷰 거래소(Review Service Exchange): 리뷰 거래소는 ‘램’ 안에 위치하며,
      리뷰 서비스가 거래되는 가상의 영업장입니다. 리뷰 거래소에서 리뷰 서비스를
      사고파는 당사자는 제휴사․판매자․리뷰거래소 회원(리뷰 서비스 구매자) 대
      리뷰어입니다.
    </p>
    <p>
      ⑥ 리뷰(Review): 리뷰는 리뷰어가 램 가게와 제휴사에 의해 램 장터에 제공되는
      상품 및 서비스 중에서 선택해 ‘리뷰하기’를 누르고 리뷰하기 창에서 작성해
      리뷰 놀이터에 올린 상품 및 서비스에 대한 정직한 평가입니다. 이 경우 리뷰할
      상품 및 서비스를 선택하는 주체는 리뷰어입니다. 리뷰어는 램 가게나 램
      장터에서 자신이 원하는 대로 원하는 만큼 리뷰할 수 있습니다. 다만 소비자
      리뷰의 경우, 리뷰어가 동일한 상품을 반복 리뷰할 때는 해당 상품의 구매
      이력이 있어야 하며 반복 리뷰는 추가로 1회(총 2회)만 가능합니다. 반면
      전문가 리뷰는 제약 없이 얼마든지 비교 리뷰가 가능합니다. 예를 들어, [A 대
      B], [A 대 C], [A 대 D] 등등 시리즈 비교 리뷰가 가능합니다. 또는 ‘뉴스’의
      경우, 리뷰는 리뷰어가 리뷰하기 창을 열어 원하는 뉴스의 URL을 리뷰하기
      양식에 붙여넣기 하고 해당 뉴스에 대한 팩트 체크를 전제로 해당 뉴스를 소개,
      비판, 칭찬하는 내용입니다. 이 역시 리뷰할 뉴스를 선택하는 주체는
      리뷰어입니다. 또는 리뷰는 리뷰어가 리뷰거래소에서 제휴사(판매자,
      리뷰거래소 회원 포함)와 계약 후 제휴사가 원하는 특정 상품 또는 서비스에
      대해 ‘리뷰하기’를 누르고 리뷰하기 창에서 작성해 리뷰 놀이터에 올린 상품 및
      서비스에 대한 정직한 평가입니다. 이 경우 리뷰할 상품 및 서비스를 결정하는
      주체는 제휴사입니다. 리뷰 거래소 거래의 경우 리뷰어는 제휴사의 뜻에 따라
      ‘램’ 이외의 장소에 리뷰를 게재할 수도 있습니다.
    </p>
    <p>⑦ 일반회원: 일반회원은 ‘램’에 일반회원으로 등록한 사람입니다.</p>
    <p>
      ⑧ 리뷰 이용자: 리뷰 이용자는 일반회원으로서 리뷰어나 판매자, 리뷰거래소
      회원으로 등록하지 않은 사람입니다. 또는 이들 채널로 등록했더라도 일반회원
      채널에서 활동할 경우 리뷰 이용자에 해당합니다. 리뷰 이용자는 램에서 리뷰를
      매개로 상품 및 서비스를 구매할 수 있고, 상품 및 서비스 구매․사용 후 리뷰와
      관련된 각종 평가 활동에 참여할 수 있으며, 이 경우 램 포인트를 지급받을 수
      있습니다. 램 포인트 지급 여부와 양은 회사의 정책에 따라 달라질 수
      있습니다.
    </p>
    <p>
      ⑨ 리뷰어: 리뷰어는 일반회원으로서 [리뷰어 채널 등록 신청하기]를 통해
      ‘램’에 리뷰어로 등록한 사람입니다. 리뷰어는 램 장터에 제공되는 상품 및
      서비스 정보를 바탕으로 리뷰를 작성해 리뷰 놀이터에 올릴 수 있고, 자신의
      리뷰를 통해 상품 및 서비스 구매가 이루어질 경우 건당 리뷰 보상 포인트를
      지급받을 수 있습니다. 판매 건당 리뷰 보상 포인트의 비율은 회사의 정책을
      따릅니다. 또는 뉴스 등 특정 서비스의 경우, 리뷰어는 외부의 정보를 가져와
      리뷰를 작성해 리뷰 놀이터에 올릴 수도 있습니다. 이 경우 리뷰어는 다른
      방식으로 리뷰 보상 포인트를 지급받을 수 있으며 그 지급 여부와 양은 회사의
      정책에 따라 결정됩니다. 아울러 리뷰어들 중 만 19세 이상인 회원은
      리뷰거래소에서 리뷰 서비스를 판매할 수도 있습니다. 리뷰 거래소 거래의 계약
      액수는 거래 당사자들에 의해 결정됩니다.
    </p>
    <p>
      ⑩ 생산자: 생산자는 일반회원으로 ‘램’에 등록하지는 않습니다. 생산자는
      리뷰앤메이크머니와 계약관계를 맺고 생산자 리뷰란에 자신들의 제품이나 자체
      공장, 서비스 리뷰를 올릴 수 있습니다. 생산자는 이 생산자 리뷰를 단지
      홍보용으로 게시만 할 수도 있고 동일 리뷰를 통해 자신들의 제품 및 서비스를
      판매할 수도 있습니다. 생산자 회원은 리뷰거래소에 리뷰거래소 회원으로 등록
      후 리뷰어의 리뷰 서비스를 구매할 수도 있습니다.
    </p>
    <p>
      ⑪ 판매자: 판매자는 램 가게에 입점한 회원 또는 제휴몰 회원으로서 ‘램’에
      판매자로 등록된 개인 또는 법인입니다. 판매자는 램 장터에 상품 및 서비스
      정보를 제공해 리뷰어로 하여금 리뷰하게 하고 이를 통해 상품 및 서비스를
      판매할 수 있으며, 자체 상품 및 서비스에 대해 스스로 리뷰할 수도 있고,
      리뷰거래소에서 리뷰어의 리뷰 서비스를 구매할 수도 있습니다.
    </p>
    <p>
      ⑫ 제휴사: 제휴사는 본 회사와 ‘램’을 이용하기로 계약한 업체입니다. 제휴사는
      ‘램’의 회원자격을 갖습니다. 제휴사 역시 ‘램’에서 판매자와 동일한 권리를
      행사할 수 있습니다.
    </p>
    <p>
      ⑬ 채널: ‘채널’은 램 플랫폼 안에서 일반회원, 리뷰어 회원, 판매자 회원, 리뷰
      거래소 회원이 등록 및 개설해 사용하는 영역입니다. 일반회원은 일반회원
      채널에서, 리뷰어 회원은 리뷰어 회원 채널에서, 판매자 회원은 판매자 회원
      채널에서, 리뷰 거래소 회원은 리뷰 거래소 회원 채널에서 각 채널에 해당하는
      기능들을 사용할 수 있습니다.
    </p>
    <p>
      ⑭ 비회원: 비회원은 ‘램’에 회원으로 등록하지 않은 사람입니다. 비회원 역시
      리뷰 놀이터의 리뷰나 램 장터의 상품/서비스를 살펴볼 수 있습니다. 그러나
      리뷰거래소나 램 장터 또는 램 가게를 통해 상품 및 서비스를 구매할 수 없고
      리뷰와 연관된 다양한 행위를 할 수도 없으며 램 포인트를 지급받을 수도
      없습니다.
    </p>
    <p>
      ⑮ 아이디(ID): 아이디는 개인의 전자우편주소입니다. 아이디는 개인이 설정하고
      회사가 승인 등록하며, 아이디를 통해 개인은 ‘램’에서 고유의 정체성을 갖고,
      회사는 각 개인을 식별하게 됩니다.
    </p>
    <p>
      ⑯ 비밀번호(Password): 비밀번호는 회원이 스스로 설정해 ‘램’에 등록한 문자와
      숫자 등의 조합입니다. 회원은 ‘램’에서 비밀번호를 통해 자신의 아이디를
      입증하고 자신의 권익 및 비밀을 보호하게 됩니다.
    </p>
    <p>
      ⑰ 리뷰 보상 포인트: ‘리뷰 보상 포인트’ 또는 짧게 줄여 ‘리뷰 포인트’는 램이
      리뷰어의 리뷰에 대해 보상으로 지급하는 포인트로 서류상의 약속입니다. 리뷰
      포인트는 모든 리뷰에 지급되는 것은 아니며 해당 리뷰가 램에 재정적 이익을
      끼쳤다고 회사가 판단할 때, 회사의 정책에 따라 지급됩니다. 램에서 리뷰
      포인트의 환산 단위는 [100포인트=1원]입니다. 리뷰 포인트는 그 자체로는 그
      어떤 효용성도 갖지 못하며 현금으로 인출되지도 않습니다. 그러나 리뷰
      포인트를 램 포인트로 전환할 경우 이 포인트는 램에서 상품/서비스를
      구매․이용하는 용도로 사용 가능합니다. 리뷰 포인트의 용도와 사용 방법은
      회사의 정책에 따라 달라질 수 있으며, 변경된 회사의 정책은 해당 시점 이전의
      리뷰 포인트에도 소급 적용됩니다.
    </p>
    <p>
      ⑱ 램 포인트: 램 포인트는 리뷰 이용자들이 램 플랫폼에서 리뷰를 통해 상품 및
      서비스를 구매한 후 해당 리뷰를 평가하는 등의 활동을 할 때 이에 대해 램이
      리뷰 이용자들에게 지급하는 마일리지입니다. 램 회원은 램에서 상품 및
      서비스를 구매하거나 할 때 램 포인트를 사용할 수 있습니다.
    </p>
    <p>
      ⑲ 이 약관에서 본 조에 의해 정의되지 않은 용어들은 관련 법령 및 일반 관례에
      따릅니다.
    </p>
    <br />

    <h6>제3조 (약관의 게시와 효력 및 개정)</h6>

    <p>
      ① 회사는 본 약관과 회사소개, 개인정보처리방침, 고객센터를 램 초기화면에
      게시합니다. 아울러 회사는 대표자 성명, 영업소 소재지, 사업자등록번호 등을
      이용자가 연결화면을 통해 볼 수 있도록 합니다.
    </p>
    <p>
      ② 본 약관은 회원가입 시 본 약관에 동의한 모든 분들에게 효력이 발생합니다.
    </p>
    <p>
      ③ 회사는 ‘전자상거래 등에서의 소비자보호에 관한 법률’, ‘약관의 규제에 관한
      법률’, ‘전자문서 및 전자거래기본법’, ‘전자금융거래법’, ‘전자서명법’,
      ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’, ‘방문판매 등에 관한
      법률’, ‘소비자기본법’ 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할
      수 있습니다.
    </p>
    <p>
      ④ 회사가 이 약관을 개정할 경우, 회사는 개정약관에 개정약관의 적용일자 및
      개정사유를 명시하여 현행약관과 함께 고객센터 ‘공지사항’란에 올리고 이
      사실을 램 초기화면에 공지하며 ‘메시지/알림’ 창을 통해서도 이 사실을
      전송합니다. 공지 기간은 개정약관 적용일자 7일 전부터 적용일자
      전일까지입니다. 다만, 회원에게 불리하게 약관이 변경되는 경우 회사는
      개정약관 적용일자 30일 이전부터 적용일자 전일까지 공지하며, 회원이 개정
      전후의 내용을 명확히 알 수 있도록 수정된 항목에 대해 양쪽의 내용을 비교해
      명시합니다.
    </p>
    <p>
      ⑤ 본 조 ④항에 의해 변경된 약관은 관련 법령에 특별한 규정이나 기타 부득이한
      사유가 없는 한 그 적용일자 이전으로 소급 적용되지 않습니다.
    </p>
    <p>
      ⑥ 본 조 ④항에 따라 회원이 개정약관의 공지 기간에 명확히 거부의사를 밝히지
      않는 경우, 공지가 끝난 후 회원은 개정약관에 동의하는 것으로 간주됩니다.
      회사가 본 조 ④항의 조치를 취했음에도 회원이 이를 알지 못해 발생하는 회원의
      피해에 대해서는 회사가 책임을 지지 않습니다.
    </p>
    <p>
      ⑦ 회원이 개정약관에 동의하지 않는 경우 회사는 회원에게 개정약관을 강제할
      수 없으며, 이 경우 회원은 이용계약을 해지하고 회원탈퇴를 요청할 수
      있습니다. 아울러 회사가 기존약관을 적용할 수 없는 특별한 사정이 있는 경우
      회사는 회원과의 이용계약을 해지할 수 있습니다.
    </p>
    <p>
      ⑧ ‘램’ 내의 개별 서비스에 대해 회사는 별도의 약관 또는 이용조건을 제시할
      수 있습니다. 이 경우 회원은 개별 서비스의 약관에 대해 동의 절차를 거치게
      되며, 이때 개별 서비스의 이용약관은 본 통합약관에 우선합니다.
    </p>
    <p>
      ⑨ 이 약관에서 정하지 않은 사항과 이 약관의 해석에 대해서는 ‘콘텐츠진흥법’,
      ‘전자상거래 등에서의 소비자보호에 관한 법률’, ‘약관의 규제에 관한 법률’,
      공정거래위원회가 정하는 ‘전자상거래 등에서의 소비자 보호지침’ 등 관련 법령
      및 상관례에 따릅니다.
    </p>
    <p>
      ⑩ 이 약관의 특정 조항이 대한민국의 강행법규와 상충하는 경우 그 강행법규에
      따릅니다. 이 경우 다른 조항의 효력에는 영향을 미치지 않습니다.
    </p>
    <br />

    <h6>제4조 (서비스의 제공 및 변경)</h6>

    <p>
      ① 회사는 다음과 같은 업무를 수행합니다. <br />1. 리뷰 및 리뷰어의 신뢰도
      산정과 신뢰도에 따른 리뷰 리스트의 나열, 리뷰 보상 포인트 지급 등을 포함해
      기타 회사가 정하는 업무 <br />2.통신판매중개 서비스를 포함한 전자상거래
      서비스 및 이에 수반하는 기타 서비스
    </p>
    <p>
      ② 회사는 제휴사(램 입점 업체 포함)의 상품이 품절되거나 제휴사의 서비스가
      기술적인 이유 또는 서비스 제공자의 여건 등으로 변경될 때, 상품 또는
      서비스의 내용을 변경하기 위해 제휴사와 새로 계약을 체결할 수 있습니다. 이
      경우 변경된 상품 또는 서비스의 내용 및 제공일자를 명시하여 현재의 상품
      또는 서비스의 내용을 게시한 곳에 즉시 공지합니다.
    </p>
    <p>
      ③ 회사는 회원에게 제공하기로 계약한 서비스를 경영환경의 변화나 기술적
      사양의 변경 등으로 인해 변경할 경우 그 사유를 램 고객센터의 ‘공지사항’란에
      공지하거나, ‘메시지/알림’ 기능을 통해 회원에게 즉시 통지합니다.
    </p>
    <p>
      ④ 회사는 무료로 제공하는 서비스의 경우, 일부 또는 전부를 회사의 정책이나
      경영환경의 변화에 따라 수정, 변경, 중단할 수 있습니다. 이에 대해 관련
      법령에 특별한 규정이 없는 한 회사는 회원에게 별도의 보상을 하지 않습니다.
    </p>
    <br />

    <h6>제5조 (서비스의 중단)</h6>

    <p>
      ① 서비스는 특별한 이유가 없는 한 연중무휴, 1일 24시간 제공을 원칙으로
      합니다.
    </p>
    <p>
      ② 회사는 컴퓨터 등 정보통신설비의 보수, 점검, 교체 및 고장, 천재지변 등
      불가항력의 상황으로 인한 통신두절 등의 경우에는 서비스의 제공을 일시
      중단할 수 있습니다.
    </p>
    <p>
      ③ 회사는 본 조 ②항의 사유로 서비스의 제공이 일시적으로 중단되어 회원이
      입은 손해에 대해, 회사가 고의 또는 중대한 과실이 없는 한 책임을 지지
      않습니다.
    </p>
    <p>
      ④ 회사는 사업 종목의 전환, 사업의 종료, 회사의 폐업, 합병, 분할 등의
      이유로 서비스를 제공할 수 없게 되는 경우, 제8조에 정한 방법으로 회원에게
      통지합니다. 이 경우 회원이 보유한 포인트나 받아야 할 정산액 등 회원의
      권한에 속한 사항 외에 회사는 추가로 회원에게 보상하지 않습니다.
    </p>
    <p>
      ⑤ 회사는 필요한 경우 정기점검을 실시할 수 있으며, 정기점검 시간은 고객센터
      공지사항란과 메시지/알림, 전자우편 등을 통해 사전 공지합니다.
    </p>
    <br />

    <h6>제6조 (회원가입 및 이용계약 체결)</h6>

    <p>
      ① ‘램’에 회원가입을 원하시는 분은 본 약관 및 필수 항목의 내용에 동의하고
      회사가 정한 가입 절차를 따라 가입양식에 회원정보를 기입함으로써 회원가입을
      신청합니다.
    </p>
    <p>
      ② ‘램’에 회원가입을 마친 후 일반회원이 되신 분들 중에서 ‘리뷰어 회원’이나
      ‘판매자 회원’, ‘리뷰거래소 회원’이 되길 원하시는 분은 마이페이지(MyPage)의
      [리뷰어/판매자/리뷰거래소 채널 등록]란을 통해 소정의 절차를 거쳐 ‘리뷰어
      채널’, ‘판매자 채널’, ‘리뷰거래소 채널’을 개설하게 됩니다.
    </p>
    <p>
      ➂ 일반회원은 누구나 리뷰어 회원으로 등록이 가능하나, 만 19세 미만의 경우
      리뷰거래소에서 리뷰거래를 하는 것은 불가능합니다(다만 신청자가 만
      15세~19세 미만으로 가족관계증명서, 법정후견인 동의서를 회사에 제출한 경우,
      또는 신청자가 만 13세~15세 미만으로 가족관계증명서, 법정후견인 동의서,
      취직인허증(지방고용노동관서 발급)을 회사에 제출한 경우는 리뷰거래소 이용이
      가능합니다. 다만, 미성년자의 리뷰거래소 이용을 허용할지 여부는 회사의
      정책에 따라 달라질 수 있습니다).
    </p>
    <p>
      ➃ 일반회원은 누구나 판매자 회원 또는 리뷰 거래소 회원으로 등록이 가능하나,
      회사가 정한 절차를 거쳐야 하며, 회사의 심사 및 승인을 받아야 합니다.
    </p>
    <p>
      ⑤ 회사는 본 조 ①항에 따라 회원가입을 신청한 분들 중 아래 각 호에 해당하는
      분의 ‘램’ 또는 램 내 다양한 채널의 회원가입을 거부하거나 회원등록을 취소할
      수 있습니다. 회사가 회원가입 신청자의 신청을 거부하거나 유보하는 경우,
      회사는 회원가입 신청 시 신청자가 기입한 전자우편주소로 이를 통지합니다(단
      전자우편 주소가 잘못된 경우 회사는 통지 불가에 대해 책임지지 않습니다).
      <br />1. 회원가입 신청자가 본 약관 제7조 ③항에 의해 이전에 회원자격을
      상실한 적이 있는 경우. 다만, 제7조 ③항에 의한 회원자격 상실 후 3년이
      경과한 자로서 회사의 회원 재가입 승낙을 얻은 경우는 예외로 합니다.
      <br />2. 회원가입 신청자의 등록 내용에 허위 기재, 누락, 오기가 있는 경우.
      <br />3. 회사가 본인확인 절차를 실시할 때 본인확인이 되지 않거나 본인이
      아니라는 사실이 확인된 경우. <br />4. 일반회원 등록 시 등록 신청자가 만
      14세 미만인 경우. 만 14세 미만 아동이 부모 등 제3자의 아이디로
      상품/서비스를 구매․이용한 경우. <br />5. 리뷰어 회원 등록 시 등록 신청자가
      만 14세 미만인 경우. <br />6. 판매자 회원 등록 시 등록 신청자가 만 19세
      미만인 경우(다만, 신청자가 만 15세~19세 미만으로 가족관계증명서,
      법정후견인 동의서를 회사에 제출한 경우는 예외로 합니다. 미성년자의 판매자
      등록 여부는 회사의 정책에 따라 달라질 수 있습니다). <br />7. 기타 회원가입
      신청자를 회원으로 등록하는 것이 ‘램’의 기술상 불가능하거나 ‘램’의 운영,
      유지, 관리에 지장을 준다고 판단되는 경우.
    </p>
    <p>
      ⑥ ‘램’의 이용계약은 회원가입 신청자가 본 약관의 내용에 동의하고 회사가
      정한 가입 절차를 따라 가입 신청을 한 후 회사가 이를 승낙함으로써
      체결됩니다.
    </p>
    <p>
      ⑦ ‘램’의 회원은 회원가입 신청 시 기입한 회원의 개인정보에 변경이 있는
      경우, ‘램’의 ‘마이페이지(내 정보 관리)’에서 이를 변경해야 합니다.
      회원정보를 수정하지 않아 발생하는 회원의 손해에 대해서 회사는 그 어떤
      책임도 지지 않습니다.
    </p>
    <p>
      ⑧ 회사는 ‘영화 및 비디오물의 진흥에 관한 법률’, ‘청소년 보호법’, ‘화학물질
      관리법’ 등 관련 법령에 따른 등급 및 연령제한 준수를 위해 회원의 서비스
      이용 또는 상품 등 구매를 제한하거나, 회원에게 나이 및 본인확인 등의 절차를
      요구할 수 있습니다.
    </p>
    <p>
      ⑨ 램의 일반회원은 하나의 아이디로 총 3개의 채널을 만들어 운영할 수
      있습니다.
    </p>
    <br />

    <h6>제7조 (회원 탈퇴 및 자격 상실 등)</h6>

    <p>
      ① 회원은 회사에 언제든지 ‘램’에서의 탈퇴를 요청할 수 있으며, 회사는 회원이
      회원탈퇴를 요청할 때 이를 즉시 처리합니다.
    </p>
    <p>
      ② 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한, 정지,
      상실시킬 수 있습니다. <br />1. 회원가입 신청 시에 허위 내용을 등록한 경우.
      <br />2. 타인의 ‘램’ 이용 방해나 타인의 정보 도용 등 전자상거래 질서를
      위협하는 경우. <br />3. 램의 ‘마이페이지(내 정보 관리)’에 회원정보를 잘못
      입력하거나 변경된 사항을 수정하지 않는 등 회원의 귀책사유로 회사가
      회원에게 통지 또는 연락을 할 수 없게 된 경우. <br />4. 본 약관 제11조
      회원의 의무를 위반하는 행위를 한 경우. <br />5. 법령 또는 본 약관의 규정
      및 회사의 이용정책 등을 위반하거나 공서양속에 반하는 행위를 하는 경우.
    </p>
    <p>
      ③ 회사가 경고나 채널 일시정지, 계정 일시정지 등을 통해 회원자격을 제한한
      후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 않는
      경우, 회사는 해당 회원의 회원자격을 상실시키고 해당 계정을 삭제할 수
      있습니다.
    </p>
    <p>
      ④ 회사가 회원의 자격을 상실시키는 경우, 회사는 회원 등록을 말소합니다.
      이때 회사는 회원의 과실에 따라 리뷰어 등록만을 말소하거나 판매자 또는
      리뷰거래소 회원 등록만을 말소하거나 일반회원등록 자체를 말소하거나 하는 등
      이를 차등 적용할 수 있습니다. 이 경우 회사는 제8조에 따라 회원에게 이를
      통지하고, 등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를
      부여합니다.
    </p>
    <br />

    <h6>제8조 (회원에 대한 통지 및 광고의 게재)</h6>

    <p>
      ① 회사가 회원에게 통지를 하는 경우, 회사는 회원과 미리 약정하여 지정한
      방법대로 전자우편, 램 SMS(메시지/알림), 전화 등을 통해 할 수 있습니다.
    </p>
    <p>
      ② 회사가 불특정 다수 회원에게 통지를 하는 경우, 회사는 램 고객센터의
      공지사항란에 7일 이상 게시함으로써 개별 통지를 갈음할 수 있습니다. 다만,
      회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별
      통지를 합니다.
    </p>
    <p>
      ③ 회사는 램 서비스와 관련해 회원에게 필요한 다양한 정보를 램 고객센터의
      공지사항란이나 램 SMS(메시지/알림), 전자우편, 전화 등을 통해 제공할 수
      있습니다. 그리고 회원은 램 서비스와 관련된 필수 정보 외에 광고 등 다른
      정보들을 전자우편 등에서 수신 거절을 할 수 있습니다.
    </p>
    <p>
      ④ 회사는 램 서비스 운영과 관련해 서비스의 화면, 전자우편 등에 광고를
      게재할 수 있습니다. 램 서비스 화면에 게시되는 광고의 경우, 회원은 이를
      삭제할 권한이 없습니다. 다만, 회사 또는 광고 대행사가 광고 일시 삭제
      기능을 제공할 경우, 일시 삭제는 가능합니다. 반면 광고가 포함된 전자우편
      등은 회원이 수신 거절을 할 수 있으며, 회사는 수신 거절을 위한 방법을
      회원에게 제공합니다.
    </p>
    <br />

    <h6>제9조 (회사의 의무)</h6>

    <p>
      ① 회사는 관련 법령과 이 약관의 금지 항목, 공서양속에 반하는 행위를 하지
      않으며, 이 약관이 정하는 바에 따라 지속적이고 안정적으로 서비스를 제공하기
      위해 최선을 다합니다.
    </p>
    <p>
      ② 회사는 램 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의
      개인정보(신용정보 포함) 보호를 위한 보안 시스템을 갖추며
      개인정보처리방침을 공시하고 준수합니다.
    </p>
    <p>
      ③ 회사는 회원이 동의하지 않는 영리 목적의 광고성 전자우편이나 앱푸쉬 등을
      발송하지 않습니다.
    </p>
    <p>
      ④ 회사는 서비스 이용과 관련해 회원이 제기한 의견이나 불만이 정당하다고
      인정될 경우 이를 해결하기 위해 노력합니다. 회사는 문제해결 과정을
      고객센터의 공지사항란이나 전자우편 등을 통해 회원에게 전달할 수 있습니다.
    </p>
    <br />

    <h6>제10조 (회원의 아이디 및 비밀번호에 대한 의무)</h6>

    <p>① 아이디와 비밀번호에 대한 관리책임은 회원 본인에게 있습니다.</p>
    <p>
      ② 회원은 자신의 아이디 및 비밀번호를 제3자에게 제공하거나 제3자가 이용하게
      해서는 안 됩니다.
    </p>
    <p>
      ③ 회원은 자신의 아이디 및 비밀번호가 도난당하거나 제3자가 사용하고 있음을
      인지한 경우 즉시 회사에 통보하고 회사의 안내에 따라야 합니다. 이를 회사에
      통보하지 않거나 회사의 조치를 따르지 않는 등으로 인해 발생하는 회원의
      손실이나 손해 등에 대한 책임은 회원 본인에게 있습니다.
    </p>
    <br />

    <h6>제11조 (회원의 의무)</h6>

    <p>
      ① 회원은 관련 법령, 본 약관의 규정, 회사의 이용정책, 이용안내(FAQ),
      공지사항, 전달사항 등을 준수해야 합니다.
    </p>
    <p>
      ② 회원은 다음 각 호의 행위를 하지 말아야 합니다. <br />1. 회원신청 또는
      회원변경 시 허위 내용의 등록 <br />2. 타인의 정보 도용 <br />3. 회사가
      게시한 정보의 변경 <br />4. 회사와 기타 제3자의 저작권 등 지적재산권에
      대한 침해 <br />5. 회사와 기타 제3자의 명예를 손상시키거나 업무를 방해하는
      행위 <br />6. 본 이용약관 제13조 ②항에 해당하는 행위 <br />7. 기타
      불법적이거나 부당한 행위
    </p>
    <p>
      ③ 회원은 다음 각 호의 행위로 회사의 업무를 방해하지 말아야 합니다.
      <br />1. 회사가 제공하지 않는 방법으로 서비스에 접속하거나 해킹, 컴퓨터
      바이러스, 논리폭탄, 메일폭탄 따위의 방법으로 회사를 공격하는 등의 시스템
      교란 행위 <br />2. 회사가 제공하는 각종 서비스 및 정보를 복제, 분해, 모방
      또는 변경하는 행위 <br />3. 자동 접속 프로그램 또는 해킹 프로그램 등을
      통해 리뷰어 순위나 리뷰 순위, 댓글 등을 조작하는 행위 <br />4. 회사의
      서비스 제공 목적과 다른 목적으로 서비스를 악용해 회사의 서버, 데이터베이스
      등 정보처리자원을 소모하게 하는 행위 <br />5. 리뷰 서비스 거래 당사자들이
      ‘리뷰거래소’를 이용하지 않고 직접 거래해 차후에 리뷰어가 이를 램 리뷰
      놀이터에 게재하는 행위(일명 ‘뒷광고’) <br />6. 판매자(제휴사와 생산자
      포함)가 ‘램 장터’를 이용하지 않고 구매자(리뷰 이용자)와 직접 거래하거나
      이를 유도하는 행위 <br />7. 회사가 제공하는 결제방법을 배제하고 상품이나
      서비스 할인 등을 미끼로 에스크로 미적용 된 무통장 입금이나 계좌이체를
      유도하는 등의 결제 부정 행위 <br />8. 저작권법, 정보통신망 이용촉진 및
      정보보호 등에 관한 법률, 전산망 보급 확장과 이용촉진에 관한 법률,
      전기통신사업법, 여신전문금융업법 등 회사 또는 제3자의 권리를 침해하거나
      전자상거래 질서를 위반하는 행위 <br />9. 구매의사 없이 구매와 청약철회를
      반복하거나, 정당한 사유 없이 교환/반품을 해 판매자에게 손해를 끼치거나, 램
      가게 Q&A란에 가격 비교, 비방, 욕설 등을 게시함으로써 판매자의 판매활동을
      방해하는 행위 <br />10. 회사의 임직원 및 관련자에게 욕설 등 명예를
      훼손하는 말이나 행동을 하거나 거래통념상 비합리적인 요구를 하거나 회사의
      업무와 무관한 연락을 하는 등의 행위 <br />11. 그 외 위 각 호의 행위와
      유사하거나 준하는 업무 방해 행위
    </p>
    <p>
      ④ 회사는 본 조 ②항과 ③항을 위반하는 회원에 대해 경고, 벌점, 채널 일시정지,
      채널 삭제, 계정 일시정지, 계정 영구정지, 계정 상실, 회원 등록 말소, 게시물
      말소, 리뷰 포인트와 램 포인트 지급 취소 및 환수 등의 조치 및 민․형사상의
      조치를 취할 수 있습니다.
    </p>
    <p>
      ⑤ 회사는 본 조 ④항에 해당하는 회원에게 램의 ‘메시지/알림’ 또는 회원의
      전자우편 등을 통해 회사의 조치를 통지하고, 회원은 이에 대해 소명할 수
      있습니다.
    </p>
    <br />

    <h6>제12조 (저작권의 귀속 및 이용 제한)</h6>

    <p>
      ① 회사가 작성한 저작물에 대한 저작권 및 기타 지적재산권은 회사에
      귀속합니다.
    </p>
    <p>
      ② 회원은 회사에 지적재산권이 있는 정보를 회사의 사전 승낙 없이 복제, 송신,
      출판, 배포, 방송 등의 방법으로 영리 목적을 위해 이용하거나 제3자에게
      이용하게 해서는 안 됩니다.
    </p>
    <br />

    <h6>제13조 (회원의 게시물)</h6>

    <p>
      ① 리뷰를 포함해, 회원이 회사가 제공하는 서비스를 이용해 작성한 각종
      게시물들의 저작권은 회원 본인에게 있으며, 해당 게시물의 저작권 침해를
      비롯한 민․형사상의 모든 책임은 회원에게 있습니다.
    </p>
    <p>
      ② 회사는 회원이 게시하거나 등록, 링크하는 게시물의 내용이 다음 각 호 중
      하나에 해당한다고 판단되는 경우 사전통지 없이 게시를 중지하거나 삭제할 수
      있고, 채널 일시정지 또는 영구정지, 계정 삭제 및 회원등록 말소를 할 수
      있습니다. <br />1. 리뷰 게시물이 관련 상품이나 서비스와 전혀 관련이 없는
      경우 <br />2. 리뷰 게시물이 거짓, 과장, 기만, 부당한 비교나 근거 없는
      비방을 포함하는 경우 <br />3. 리뷰 게시물이 언론사 뉴스 등의 콘텐츠를
      무단복사, 붙여넣기한 경우 <br />4. 게시물이 타인의 저작권, 상표권 등
      권리를 침해하는 경우 <br />5. 게시물이 관련 법령을 위반하거나 범죄행위와
      관련된 내용을 포함하는 경우 <br />6. 게시물이 불법물, 음란물,
      청소년유해매체물 등 외설 또는 폭력적인 내용을 포함하거나 이를 등록,
      링크하는 경우 <br />7. 게시물이 램 플랫폼의 성격과 무관하며 영리 목적의
      광고성 내용을 포함하는 경우 <br />8. 게시물이 특정인의 개인정보를 노출하는
      경우 <br />9. 게시물이 특정인의 사생활을 침해하거나 명예를 훼손하는 경우
      <br />10. 게시물이 정당한 사유 없이 회사의 영업을 방해하는 내용을 포함하는
      경우 <br />11. 게시물이 악성코드나 데이터를 포함하는 경우
    </p>
    <p>
      ③ 본 조 ②항에 해당하는 게시물로 인해 법률상 이익이 침해된 자는 관련 법령이
      정한 절차에 따라 회사 신고센터에 해당 게시물의 게시중단 및 삭제 등을
      요청할 수 있으며, 회사는 관련 법령 및 회사의 정책에 따라 조치를 취합니다.
      단, 회사가 권리침해 여부를 판단하기 어렵거나 이해당사자 간에 다툼이
      예상되는 경우 회사는 30일간 해당 게시물의 공개를 임시로 차단할 수
      있습니다.
    </p>
    <p>
      ④ 회원탈퇴의 경우 회원이 램에 작성한 게시물들 역시 삭제되므로 회원은 원할
      경우 본인이 작성한 게시물에 대해 사전에 복사․저장 등의 조치를 취해야
      합니다.
    </p>
    <p>
      ⑤ 본 조 ②항에 해당하는 게시물로써 게시중단 및 삭제 처리된 게시물에 지급된
      리뷰 포인트와 램 포인트는 환수될 수 있습니다.
    </p>
    <p>
      ⑥ 회원은 회사에 회원의 게시물을 무상, 비독점적으로 사용(복제, 배포, 전송,
      전시, 편집, 수정, 가공 등)할 권리를 부여합니다.
    </p>
    <br />

    <h6>제14조 (리뷰/리뷰어의 신뢰도 산정과 리뷰-보상 체계)</h6>

    <p>
      ① 사이버몰 램은 리뷰를 매개로 상품 및 서비스의 구매가 이루어지는 가상의
      공간입니다.
    </p>
    <p>
      ② 램 플랫폼에서 회원에게 지급, 사용되는 포인트는 두 가지입니다. ‘램
      포인트’와 ‘리뷰 보상 포인트’입니다.
    </p>
    <p>
      ③ 리뷰 이용자는 램에서 리뷰를 통해 상품 및 서비스를 구매하고, 이를
      사용해본 후 해당 리뷰와 관련해 ‘리뷰 만족도’나 ‘제품 평점’ 매기기 등 여러
      행위에 참여합니다. 회사는 리뷰와 관련된 리뷰 이용자의 이런 행위에 대해
      리뷰 이용자에게 ‘램 포인트’를 지급할 수 있습니다(램 자체 오픈마켓인 램
      가게와 제휴몰의 경우에만 해당).
    </p>
    <p>
      ④ 리뷰에 대한 리뷰 이용자들의 다양한 행위에 의해 해당 리뷰의 신뢰도가
      결정됩니다. 리뷰의 신뢰도가 결정되는 요소는 다양합니다. 리뷰의 신뢰도는
      리뷰만족도, 제품/서비스 평가지수, 구매유인도, 댓글, 벌점 등 다양한 요소가
      결합해 결정됩니다.
    </p>
    <p>
      ⑤ 리뷰어의 신뢰도는 리뷰의 신뢰도가 쌓여 결정됩니다. 리뷰어의 신뢰도는
      해당 리뷰어가 작성한 모든 리뷰들의 신뢰도 합산 평균입니다.
    </p>
    <p>
      ⑥ 회사는 리뷰 놀이터에 리뷰 신뢰도순으로 상품 및 서비스의 리뷰를
      게시합니다. 또는 몇몇 서비스의 경우, 컨텐츠 중요도순으로 리뷰를 게시할
      수도 있습니다. 이 외에 리뷰어 신뢰도순, 최신순 등 리뷰 놀이터에서 리뷰들이
      정렬되는 순서는 다양합니다.
    </p>
    <p>
      ⑦ 신뢰도 상위의 리뷰는 그 자체로 보상입니다. 그 리뷰를 통해 상품 또는
      서비스의 구매가 일어나게 되고, 그 리뷰의 리뷰어는 구매 건당 ‘리뷰 보상
      포인트’를 받게 됩니다(램 자체 오픈마켓인 램 가게와 제휴몰의 경우에만
      해당). 또는 뉴스의 경우처럼 비제휴몰인 경우 리뷰어는 다른 방식으로 리뷰
      보상 포인트를 지급받을 수 있습니다. 비제휴몰 리뷰처럼 회사가 직접 이익을
      낼 수 없는 모델의 경우, 리뷰어의 리뷰에 대한 보상 포인트 지급 여부와 양은
      회사의 경영상태와 정책에 따라 달라집니다.
    </p>
    <p>
      ⑧ 리뷰 보상 포인트나 램 포인트를 상품 또는 서비스의 매매 건당 해당 금액의
      몇 % 또는 얼마로 할지는 회사의 리뷰-보상 운영정책에 따라 달라질 수
      있습니다.
    </p>
    <p>
      ⑨ 리뷰나 리뷰어의 신뢰도를 확립하는 주체는 리뷰 이용자들입니다. 회사는
      리뷰나 리뷰어의 신뢰도를 인위적으로 조작하지 않습니다.
    </p>
    <p>
      ⑩ 위 ①~⑨까지의 내용은 리뷰 놀이터의 리뷰를 통한 상품 또는 서비스 거래에
      해당합니다. 램 장터나 램 가게를 통한 거래의 경우 리뷰를 통한 거래가
      아니므로 위 항목들이 적용되지 않습니다.
    </p>
    <p>
      ⑪ 이외에 자세한 사항은 회사의 운영정책을 따릅니다. 운영정책은 고객센터의
      FAQ에 잘 요약되어 있습니다.
    </p>
    <br />

    <h6>제15조 (포인트)</h6>

    <p>
      ① ‘램 포인트’와 ‘리뷰 보상 포인트’는 모두 ‘선불전자지급수단’이 아니며 램
      플랫폼 밖에서 사용할 수 없습니다. 두 종류의 포인트 모두 램에서 자체적으로
      발행하며 램 플랫폼 안에서만 사용되는 무료 포인트입니다.
    </p>
    <p>
      ② ‘램 포인트’는 마일리지로써 램에서는 통용되나 현금 전환은 불가능합니다.
      ‘리뷰 보상 포인트’ 역시 현금으로 전환 또는 인출되지 않으며, 그 자체로
      램에서 사용되지도 않습니다. 그러나 리뷰어는 리뷰 보상 포인트를 램 포인트로
      전환할 수 있으며, 전환할 경우 램에서 상품 또는 서비스를 구매․이용하는
      용도로 사용할 수 있습니다. 램 포인트와 리뷰 포인트 모두 타인에게 양도할 수
      없습니다.
    </p>
    <p>
      ③ 회사는 사이버몰 램에서 상품 및 서비스 구매 또는 리뷰 신뢰도 확립에
      기여한 리뷰어 회원과 리뷰 이용자(소비자) 회원에게 리뷰 포인트와 램
      포인트를 지급할 수 있습니다. 램 포인트는 회사가 지급하는 마일리지입니다.
      반면 리뷰 포인트는 회사가 지급하는 리뷰 보상 포인트입니다. 지급기준은
      회사의 리뷰-보상 정책을 따릅니다.
    </p>
    <p>
      ④ 회사는 회원의 리뷰나 댓글이 제11조 ③항, 제13조 ②항에 해당하는 경우 또는
      구매확정 후 반품 등으로 인한 경우 회원에게 램 포인트와 리뷰 포인트 지급을
      취소하거나 지급된 포인트를 환수할 수 있습니다.
    </p>
    <p>
      ⑤ 회사는 회원의 리뷰나 댓글이 제11조 ③항, 제13조 ②항에 해당하는 경우 또는
      구매확정 후 반품 등으로 인한 경우 등을 제외하고 회원이 합법적인 활동으로
      지급받은 램 포인트와 리뷰 포인트를 환수할 수 없습니다.
    </p>
    <p>
      ⑥ 회사는 리뷰어 회원의 활동이 제11조 ③항, 제13조 ②항에 해당하고 과실이
      중하다고 판단해 리뷰어 회원의 채널을 삭제하는 조치를 취할 경우, 본 조
      ③항과 ④항에 따라 회원에게 남겨진 리뷰 포인트를 램 포인트로 전환한 후 해당
      채널을 삭제합니다.
    </p>
    <p>
      ⑦ 회사는 회원의 활동이 제11조 ③항, 제13조 ②항에 해당하고 과실이 중하다고
      판단해 해당 회원의 계정 영구정지, 계정 상실, 회원등록말소 등의 조치를 취할
      때, 본 조 ③항과 ④항에도 불구하고 해당 회원의 램 포인트와 리뷰 포인트
      전부를 말소합니다.
    </p>
    <p>
      ⑧ 회원이 램을 탈퇴할 경우, 해당 회원의 램 포인트와 리뷰 포인트는
      회원탈퇴와 함께 자동 소멸합니다.
    </p>
    <p>
      ⑨ 램 포인트와 리뷰 포인트의 용도와 처리 방식은 회사의 정책에 따라 달라질
      수 있으며, 회사의 정책이 달라질 경우 회사는 이를 고객센터의 공지사항란에
      사전에 공지합니다.
    </p>
    <p>
      ⑩ 램 플랫폼에서 통용되는 포인트의 소멸시효는 5년입니다. 램 회원 당사자가
      포인트를 사용한 시점부터 5년간 포인트를 사용하지 않을 경우 해당 포인트는
      소멸됩니다.
    </p>
    <br />

    <h6>제16조 (회원의 대금지급방법)</h6>

    <p>
      ① 회원은 사이버몰 램에서 구매한 상품 및 서비스 등에 대해 대금을 지급할 때
      다음 각 호 중 회사가 제공하는 방법으로 할 수 있습니다. <br />1. 선불카드,
      직불카드, 신용카드 등의 각종 카드 결제 <br />2. 전화 또는 휴대전화를
      이용한 결제 <br />3. 폰뱅킹, 인터넷뱅킹, 메일뱅킹, 온라인 무통장 입금 등의
      각종 계좌이체 <br />4. 램 포인트를 이용한 결제 <br />5. 캐시백, 신용카드
      등의 포인트에 의한 결제 <br />6. 회사와 계약을 맺었거나 회사가 인정한
      상품권에 의한 결제 <br />7. 기타 전자적 지급 방법에 의한 대금 지급 등
    </p>
    <p>
      ② 회원은 램에서 본 조 ①항의 결제 수단을 사용할 때 반드시 본인 명의의 결제
      수단을 사용해야 합니다. 회원이 타인의 결제 수단을 사용해 회사, 결제 수단의
      적법한 소유자, 결제 관련 제3자에게 손해와 손실을 입힐 경우, 이에 대한
      책임은 전적으로 회원에게 있습니다.
    </p>
    <p>
      ③ 상품 등의 구매대금을 결제할 때 회원이 입력한 정보 및 그 정보와 관련해
      발생한 책임과 불이익은 전적으로 회원이 부담합니다.
    </p>
    <p>
      ④ 회원은 구매대금 결제 시 적법한 결제수단을 사용해야 하며, 회사는 그
      여부를 확인할 수 있습니다. 회사는 결제수단의 적법성 여부가 확인될 때까지
      거래진행을 중지하거나 확인 완료 후 해당 거래를 취소할 수 있습니다.
    </p>
    <p>
      ⑤ 회사의 정책 및 본 조 ①항의 결제업체가 정한 기준에 따라 회원의 당월 누적
      결제액이 제한될 수 있습니다. 해당 기준이 초과된 경우 상품 등의 구매가
      불가능할 수 있습니다.
    </p>
    <br />

    <h6>제17조 (개인정보보호)</h6>

    <p>
      ① 회사는 램 이용자의 개인정보를 수집할 경우, 서비스 제공을 위해 필요한
      범위에서 최소한의 개인정보를 수집합니다.
    </p>
    <p>
      ② 회사는 회원가입 시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다.
      다만, 관련 법령상 의무이행을 위해 구매계약 이전에 본인확인이 필요한 경우
      최소한의 범위에서 개인의 특정 정보를 수집합니다.
    </p>
    <p>
      ③ 회사는 이용자의 개인정보를 수집․이용할 때 이용자 본인에게 그 목적을
      고지하고 동의를 받습니다.
    </p>
    <p>
      ④ 회사는 수집된 개인정보를 목적 외의 용도로 이용하지 않으며, 새로운
      이용목적이 발생한 경우 또는 제3자에게 제공하는 경우 이용․제공 단계에서
      이용자 본인에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령이 달리
      정하는 경우는 예외로 합니다.
    </p>
    <p>
      ⑤ 회사는 본 조 ②항과 ③항에 의해 이용자의 동의를 받아야 하는 경우
      개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의
      수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적
      및 제공할 정보의 내용) 등 ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’
      제22조 제2항이 규정한 사항을 미리 명시하거나 고지하며 이용자는 언제든지 이
      동의를 철회할 수 있습니다.
    </p>
    <p>
      ⑥ 이용자는 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및
      오류정정을 요구할 수 있으며 회사는 이에 대해 지체없이 필요한 조치를
      취합니다. 이용자가 오류정정을 요구한 경우에는 회사는 그 오류를 정정할
      때까지 해당 개인정보를 이용하지 않습니다.
    </p>
    <p>
      ⑦ 회사는 개인정보보호를 위해 이용자의 개인정보를 취급하는 자를 최소한으로
      제한하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보 분실, 도난,
      유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대해 모든
      책임을 집니다.
    </p>
    <p>
      ⑧ 회사 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는
      제공받은 목적을 달성한 때에는 해당 개인정보를 지체없이 파기합니다.
    </p>
    <p>
      ⑨ 회사는 개인정보 수집․이용․제공에 관한 동의란을 미리 선택한 것으로
      설정해두지 않습니다. 또한 개인정보의 수집․이용․제공에 관한 이용자의 동의
      거절 시 제한되는 서비스를 구체적으로 명시하고, 필수 수집항목이 아닌
      개인정보의 수집․이용․제공에 관한 이용자의 동의 거절을 이유로 회원가입 등
      서비스의 제공을 제한하거나 거절하지 않습니다.
    </p>
    <p>
      ⑩ 램에 링크된 사이버몰에는 회사의 개인정보처리방침이 적용되지 않습니다.
      링크된 사이버몰의 개인정보처리방침 및 해당 사이버몰에서 상품이나 서비스
      등을 구매하는 단계에서 제3자의 개인정보 취급과 관련해 제3자의
      개인정보처리방침을 확인할 책임은 회원에게 있으며 회사는 이에 대해 책임을
      지지 않습니다.
    </p>
    <p>
      ⑪ 회사는 다음 각 호의 경우 법이 허용하는 범위 내에서 회원의 개인정보를
      제3자에게 제공할 수 있습니다. <br />1. 회원이 관련 법령 등을 위반한 것이
      명백해 보일 때 회원의 부정행위를 확인하기 위해 필요한 경우. <br />2.
      제휴사, 생산자, 판매자 등이 상품/서비스 거래 등에 회원정보(성명, 주소,
      전화번호 등 최소한의 정보)를 필요로 하는 경우. <br />3. 기타 관련 법령에
      의해 요구되는 경우.
    </p>
    <p>
      ⑫ 기타 본 조에서 정하지 않은 사항은 회사의 ‘개인정보처리방침’을 따릅니다.
    </p>
    <br />

    <h5>제2장 램 가게/리뷰거래소(통신판매중개) 서비스</h5>

    <h6>제18조 (서비스의 종류)</h6>

    <p>
      회사가 사이버몰 램(https://reviewnmakemoney.com,
      https://m.reviewnmakemoney.com)에서 회원에게 제공하는 서비스의 종류는
      다음과 같습니다.
    </p>
    <p>① 상품 및 서비스의 리뷰 검색정보 서비스</p>
    <p>
      ② 리뷰/리뷰어 신뢰도 산정 및 리뷰의 신뢰도 순위별 ‘리뷰 놀이터’ 게시
      서비스
    </p>
    <p>③ 리뷰를 통한 상품 및 서비스 거래 서비스</p>
    <p>④ 리뷰거래소를 통한 리뷰 작성 거래 서비스</p>
    <p>⑤ 리뷰거래소의 1:1 메신저 서비스</p>
    <p>⑥ 상품 및 서비스의 검색정보 서비스</p>
    <p>⑦ 램 입점업체 및 리뷰어 검색정보 서비스</p>
    <p>⑧ 램 장터 및 램 가게를 통한 상품 및 서비스 거래 서비스</p>
    <p>⑨ 메시지/알림 서비스</p>
    <p>⑩ 판매 관련 업무지원 서비스</p>
    <p>⑪ 구매 관련 지원 서비스</p>
    <p>⑫ 계약체결, 결제 지원 및 결제대금 보호 서비스</p>
    <p>⑬ 기타 전자상거래 관련 서비스</p>
    <br />

    <h6>제19조 (회원의 서비스 이용)</h6>

    <p>
      ① 램 가게(제휴몰 포함) 및 리뷰거래소와 관련해 회사는 통신판매중개자로서
      판매자와 구매자 간의 자유로운 상품 또는 서비스 거래를 위한 시스템을 운영,
      관리, 제공합니다.
    </p>
    <p>
      ② 램 가게(제휴몰 포함)의 경우, 제휴사나 판매자는 판매할 상품 또는 서비스의
      정보를 램 장터에 제공하고(램 가게의 상품과 서비스는 자동으로 램 장터에
      노출) 리뷰어는 그 중 원하는 상품 또는 서비스를 선택, 리뷰해 리뷰 놀이터에
      올립니다.
    </p>
    <p>
      ③ 리뷰 이용자는 리뷰 놀이터에서 본인이 원하는 상품/서비스 리뷰를 검색하고
      검토한 후 구매 여부를 결정합니다. 거래 과정은 다음과 같습니다. <br />1.
      상품 또는 서비스의 리뷰 검색 <br />2. 여러 리뷰들 중 원하는 리뷰 선택
      <br />3. 리뷰 하단 상품/서비스의 ‘구매하기’ 버튼 클릭 <br />4. 해당
      제휴사나 램 가게로 이동해 구매신청 <br />5. 결제방법의 선택 <br />6.
      구매․사용 후 해당 상품 또는 서비스를 추천한 리뷰 평가
    </p>
    <p>
      ④ 리뷰거래소의 경우, 회사가 제공하는 거래양식을 따라 제휴사(제안사)와
      리뷰어가 직접 리뷰 작성 서비스를 거래합니다. 거래 과정은 다음과 같습니다.
      <br />1. 리뷰어 모집 공지 또는 리뷰어 검색 등을 통해 리뷰어 물색 및
      선택(리뷰어의 경우 리뷰 서비스 구매자 모집 공지를 통한 리뷰 서비스 구매자
      물색 및 선택) <br />2. 리뷰 서비스 구매자와 리뷰어는 회원가입 시 등록된
      회원정보 외에 거래를 위한 최소한의 정보만 거래양식에 입력 <br />3.
      리뷰거래소에서 제공하는 거래양식을 따라 협상 <br />4. 협상 타결 시 리뷰
      서비스 구매신청 <br />5. 결제방법의 선택 및 결제 <br />6. 계약 이행이 끝난
      후 상호 신뢰도 평가
    </p>
    <p>
      ⑤ 회사는 통신판매중개자로서 회원 상호간의 자유로운 거래 활동을 도울
      뿐이므로, 회원 사이의 거래에 대한 책임과 회원이 제공한 정보에 대한 책임은
      해당 회원이 직접 부담합니다.
    </p>
    <p>
      ⑥ 회원은 본 약관 및 회사가 서비스 화면에 공지하는 내용을 준수해야 하고, 본
      약관 및 공지 내용을 위반하거나 이행하지 않아 발생하는 모든 손해에 대하여
      책임을 집니다.
    </p>
    <p>
      ⑦ 구매자는 판매자와 상품 등 매매 절차에서 분쟁이 발생하면 분쟁의 해결을
      위하여 성실히 임해야 하며, 분쟁 해결의 불성실로 판매자와 회사에 손해가
      발생하면 그에 대한 모든 책임을 부담합니다.
    </p>
    <p>
      ⑧ 회사는 구매자의 매매대금 결제 시 해당 결제수단에 대하여 정당한 사용
      권한이 있는지 확인할 수 있고, 이에 대한 확인이 완료될 때까지 해당 거래의
      진행을 중지할 수 있습니다.
    </p>
    <p>
      ⑨ 회사는 회원에게 서비스가 안전하게 제공될 수 있도록 각종 설비와 자료를
      관리하고, 서비스가 제공 목적에 맞게 이용되고 있는지 여부를 확인합니다.
      만약 회원이 이용 목적을 위반한 것으로 확인되면 회사는 그에 대한 소명을
      회원에게 요청할 수 있고, 필요한 조치를 취할 수 있습니다.
    </p>
    <p>
      ⑩ 미성년자인 회원이 사이버몰 램을 통해 법정대리인의 동의 없이 상품이나
      서비스를 구매하는 경우 본인 또는 법정대리인은 해당 계약을 취소할 수
      있습니다.
    </p>
    <br />

    <h6>제20조 (결제대금예치서비스의 이용)</h6>

    <p>
      ① ‘결제대금예치서비스’는 매매계약 체결 후 회원이 대금을 결제하는 경우,
      회원의 결제대금 보호를 위해 회사가 일정 기간 동안 결제대금을 예치하는
      서비스를 말합니다.
    </p>
    <p>
      ② 결제대금예치서비스는 사이버몰 램에서 거래되는 모든 상품과 리뷰거래소의
      리뷰 구매에 적용됩니다. 다만, 결제대금예치서비스가 필요없는 서비스의 경우
      적용되지 않습니다.
    </p>
    <p>
      ③ 결제대금예치서비스는 회원 간에 이루어지는 상품 및 서비스 매매의 안전성과
      신뢰성을 높이고, 상품을 인수하거나 계약이 이행되기 전에 대금을 결제해야
      하는 구매자 보호를 위해 제공됩니다.
    </p>
    <p>
      ④ 회사에서 제공하는 결제대금예치서비스는 판매자나 구매자를 대리하는 것이
      아니며, 상품 등의 매매와 관련하여 판매자나 구매자의 의무를 대행하는 것도
      아닙니다.
    </p>
    <p>
      ⑤ 회사의 결제대행 서비스를 통하여 상품구매대금을 결제하는 과정에서
      발생하는 송금수수료 등은 대금을 송금하는 구매자가 부담해야 합니다.
    </p>
    <p>
      ⑥ 결제대금예치서비스를 제공하는 과정에서 회사가 결제대금을 보관하여 이자
      등이 발생했을 때 구매자는 회사에 그 반환을 청구할 수 없습니다.
    </p>
    <p>
      ⑦ 결제대금예치서비스를 이용하는 구매자는 자동구매확정 기간 내(리뷰거래소의
      경우, 신고 기간 내 즉 리뷰어가 ‘미션완료’ 버튼을 누른 후 6일 이내)에
      회사에 구매확정, 교환, 반품 또는 지불, 신고 등의 의사표시를 해야 합니다.
    </p>
    <p>
      ⑧ 구매자에게 구매확정의 의사가 있거나 구매확정의 의사가 있는 것으로
      간주되는 상황이 발생하면 결제대금예치서비스는 종료됩니다.
    </p>
    <p>
      ⑨ 구매확정기간 내에 구매자가 교환 또는 반품의 의사표시가 없으면 회사는
      구매자에게 구매확정의 의사가 있다고 간주합니다.
    </p>
    <p>
      ⑩ 회사는 구매자에게 구매확정의 의사가 있거나 구매확정의 의사가 있다고
      간주하면 ’전자상거래 등에서의 소비자보호에 관한 법률’의 관련 규정에 따라
      관련 대금을 상품/서비스의 판매자에게 송금할 수 있습니다.
    </p>
    <p>
      ⑪ 결제대금예치서비스가 종료된 이후에 청약철회, 취소, 해제, 무효 등의
      사유가 발생하면 구매자는 판매자와 직접 청약철회, 취소, 해제 및 결제대금
      환불 등에 관한 절차를 진행해야 합니다.
    </p>
    <p>⑫ 회사는 결제대금예치서비스를 제3자에게 위탁․시행할 수 있습니다.</p>
    <br />

    <h6>제21조 (배송 및 거래완료)</h6>

    <p>
      ① 회사는 상품 등의 배송과 관련해 판매자, 구매자, 배송업체 등 관련 당사자
      사이에 분쟁이 발생하는 경우 이에 관여하지 않습니다. 해당 분쟁은 관련
      당사자들이 해결하는 게 원칙입니다. 상품의 손실이나 훼손 등에 대해서는 이를
      유발한 해당 당사자가 책임을 부담합니다.
    </p>
    <p>
      ② 배송 소요 기간은 입금이 확인된 날의 다음 날을 기산일로 배송이
      완료되기까지의 기간을 말합니다. 공휴일과 기타 휴무일 또는 천재지변 등
      불가항력의 사유가 발생하면 그 해당 기간은 배송 소요 기간에서 제외됩니다.
    </p>
    <p>
      ③ 구매자는 구매한 상품 등의 배송이 완료된 시점부터 일정한 기간 내에 회사에
      대하여 구매확정, 교환 또는 반품의 의사표시를 해야 합니다.
    </p>
    <p>
      ④ 회사는 회사가 정한 구매확정 기간 내에 구매확정, 교환 또는 반품에 대한
      구매자의 의사표시가 없는 경우, 해당 거래에 대하여 구매자의 구매확정
      의사표시가 있는 것으로 간주하여 자동구매확정으로 처리할 수 있습니다. 다만,
      구매확정 기간 내에 구매자가 실제 상품을 수령하지 못한 경우, 구매자는 상품
      미도착 신고를 할 수 있습니다.
    </p>
    <p>
      ⑤ 회사가 정한 구매확정 기간은 출고가 완료된 시점을 기준으로 이후
      5~10일입니다. 구매자는 상품을 배송받은 후 위 기간 내에 또는 그 이전에라도
      구매확정을 할 수 있으며, 구매자가 위 기간 내에 구매확정을 하지 않을 경우
      위 기준 10일째에 자동구매확정이 됩니다.
    </p>
    <p>
      ⑥ 구매자는 구매확정 후 회사에 상품 등의 하자, 미수령, 반품 등의 사유로
      이의를 제기할 수 없습니다. 구매확정 이후에 발생하는 판매자와 구매자 간
      모든 분쟁은 거래 당사자인 판매자와 구매자가 해결해야 하며 회사는 이에
      개입하지 않습니다.
    </p>
    <p>
      ⑦ 자동구매확정 처리가 완료되면, 회사는 보관 중인 결제대금 중 판매자에게
      지급할 상품판매대금을 판매자가 지정한 방법으로 지급할 수 있습니다.
    </p>
    <p>
      ⑧ 자동구매확정이 이루어지면 구매자는 상품 미수령, 반품 등의 사유로 회사에
      이의를 제기할 수 없으며, 상품구매대금의 환불, 상품의 교환 등 모든 문제는
      판매자와 직접 해결해야 합니다.
    </p>
    <p>
      ⑨ 상품 등의 배송과 관련한 분쟁은 분쟁 당사자들 간에 해결하는 것이
      원칙이나, 분쟁 해결이 원활하지 않을 경우 당사자들은 분쟁조정센터에 분쟁
      해결을 의뢰할 수 있습니다. 회사는 분쟁 건을 한국소비자원 등 소비자피해
      분쟁조정기구에 의뢰해줄 수 있으나 회사 및 해당 기구들의 분쟁 조정안이 법적
      효력을 갖는 것은 아닙니다.
    </p>
    <br />

    <h6>제22조 (청약철회 등)</h6>

    <p>
      ① ‘전자상거래 등에서의 소비자보호에 관한 법률’ 제17조에 따라 구매자는
      상품을 배송받은 날로부터 7일 이내에 청약철회 등을 할 수 있습니다. 반품의
      경우 ‘전자상거래 등에서의 소비자보호에 관한 법률’ 등 관련 법령이 판매자가
      제시한 조건보다 우선합니다.
    </p>
    <p>
      ② 다음 각 호의 경우에는 구매자가 반품이나 교환을 요청할 수 없습니다.
      <br />1. 구매자의 귀책사유로 인해 상품 등이 훼손된 경우(다만, 구매자가
      상품 등의 내용을 확인하기 위해 포장 등을 훼손한 경우에는 예외) <br />2.
      구매자의 사용 또는 일부 소비로 상품 등의 가치가 현저히 감소한 경우
      <br />3. 시간이 지나 재판매가 어려울 정도로 상품 등의 가치가 현저히 감소한
      경우 <br />4. 복제가 가능한 상품 등의 포장을 훼손한 경우 <br />5. 서비스
      또는 디지털콘텐츠의 제공이 개시된 경우(다만, 가분적 서비스 또는 가분적
      디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한 부분에
      대해서는 청약을 철회할 수 있다) <br />6. 주문에 따라 개별적으로 생산되는
      상품의 경우처럼 청약철회 등을 인정하면 판매자에게 회복할 수 없는 중대한
      피해가 예상되는 경우로서, 사전에 해당 거래에 대하여 별도로 그 사실을
      고지하고 구매자의 서면(전자문서를 포함)에 의한 동의를 받은 경우 <br />7.
      그 밖에 거래의 안전을 위하여 대통령령이 정하는 경우
    </p>
    <p>
      ③ 구매자는 본 조 ①항과 ②항의 규정에도 불구하고 상품 등의 내용이 표시․광고
      내용과 다르거나 계약 내용과 다르게 이행된 때에는 해당 상품 등을 공급받은
      날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에
      청약 철회 등을 할 수 있습니다.
    </p>
    <p>
      ④ 회사는 구매자로부터 교환 또는 반품의 의사표시를 접수하면, 즉시 그러한
      사실을 판매자에게 통보합니다.
    </p>
    <p>
      ⑤ 구매자는 본 조 ①항 또는 ③항에 따라 교환 신청을 하더라도 판매자에게
      교환할 상품의 재고가 없는 경우에는 해당 상품 등의 교환을 받을 수 없습니다.
      이 경우 해당 교환 신청은 반품으로 처리됩니다.
    </p>
    <p>
      ⑥ 반품이나 교환에 필요한 왕복 배송비와 기타 필요한 비용은 귀책사유가 있는
      당사자가 부담합니다. 즉, 상품 등의 하자 또는 오배송으로 인한 반품이나
      교환의 경우에는 판매자가, 구매자의 단순 변심으로 인한 반품이나 교환의
      경우에는 구매자가 그 비용을 부담합니다.
    </p>
    <p>
      ⑦ 배송상의 문제로 구매자가 손해를 본 경우, 그 책임은 해당 배송업체를
      지정한 판매자가 부담합니다.
    </p>
    <p>
      ⑧ 회사가 판매자에게 구매자의 결제완료를 통지한 후 판매자가 상당 기간
      구매자의 주문 정보를 확인하지 않거나 배송 절차를 이행하지 않아 구매자가
      거래 취소를 요구하면 회사는 해당 거래를 취소할 수 있으며, 거래가 취소되면
      보관 중인 상품 구매대금은 구매자에게 환불됩니다. 또한 회사는 사전에 정한
      자동환불처리 정책에 따라 이 사안에 해당하는 경우 구매자의 요구 없이도 해당
      거래를 취소하고 자동 환불처리 등의 조치를 취할 수 있으며 그럴 경우 미리
      해당 내용을 구매자에게 공지합니다.
    </p>
    <p>
      ⑨ 판매자가 구매자의 교환, 반품에 대한 사실확인 진행절차에 적극적으로
      임하지 않거나 지연하는 경우, 회사는 구매자에게서 교환이나 반품의 원인을
      파악한 후 정당한 사유가 있으면 해당 거래를 취소하고 구매자에게 보관 중인
      결제대금을 환불할 수 있습니다. 다만, 구매자의 요청에 정당한 사유가 없는
      경우에는 환불하지 않습니다.
    </p>
    <p>
      ⑩ 구매자가 교환 또는 반품신청을 한 날로부터 14일이 지날 때까지 이미 수령한
      상품을 판매자에게 반환하지 않거나 전화, 전자우편 등으로 연락이 되지 않는
      경우, 해당 구매자의 교환 또는 반품 신청은 효력을 상실합니다.
    </p>
    <p>
      ⑪ 회사는 구매자의 교환 또는 반품의 요청이 정당하지 않다고 판단하거나 본 조
      ⑩항에 해당하는 경우, 구매자의 의사표시를 취소하고 보관 중인 결제대금을
      판매자에게 지급할 수 있습니다.
    </p>
    <p>
      ⑫ 회사는 청약철회 등으로 결제대금을 환불하는 경우, 거래가 취소된 날로부터
      3영업일 이내에 구매자에게 환불에 필요한 조치를 합니다. 신용카드로 결제했을
      때는 환불을 요청한 즉시 결제 승인이 취소됩니다. 카드결제로 구매한 건의
      환불은 카드결제 취소를 통해서만 가능하며, 어떠한 경우에도 현금잔고로
      환불되지 않습니다.
    </p>
    <p>
      ⑬ 회원은 상품이 발송되기 전까지 구매를 취소할 수 있으며, 배송 중이면
      취소가 아닌 반품절차에 의해 처리됩니다.
    </p>
    <p>
      ⑭ ‘결제완료 상태’와 ‘배송준비 중 상태’에서는 취소신청 접수 시 즉시
      취소처리가 완료됩니다.
    </p>
    <p>
      ⑮ 반품을 신청할 때 반품송장번호를 기재하지 않으면 반품처리와 환불이 지연될
      수 있습니다.
    </p>
    <br />

    <h6>제23조 (금지행위)</h6>

    <p>
      ① 회사가 제공하는 결제대금예치서비스를 이용하지 않고 판매자와 구매자가
      직접 거래하는 행위(직거래)는 거래의 안전을 위하여 금지됩니다. 또한
      직거래를 통해 발생하는 제반 문제에 대한 책임은 전적으로 거래 당사자들에게
      있으며 회사는 이에 대해 어떠한 책임도 부담하지 않습니다.
    </p>
    <p>
      ② 본 조 ①항 이외에 금지되는 회원의 행위에 대해서는 본 약관 제11조와
      제13조를 따릅니다.
    </p>
    <br />

    <h6>제24조 (분쟁조정)</h6>

    <p>
      ① 회사는 회원 상호 간 또는 회원과 제3자 간의 분쟁을 조정하기 위하여
      분쟁조정센터를 운영합니다.
    </p>
    <p>
      ① 분쟁조정센터를 통해 분쟁을 조정할 때 회사는 제3자의 입장에서 공정하게
      분쟁의 조정에 임합니다.
    </p>
    <p>
      ② 분쟁조정센터의 조정에 회원은 신의성실의 원칙에 따라 성실히 응해야
      합니다.
    </p>
    <br />

    <h6>제25조 (회사의 면책)</h6>

    <p>
      ① 회사는 통신판매중개자로서 회원 상호 간의 자유로운 상품 및 서비스의
      거래를 위한 시스템을 운영, 관리, 제공할 뿐 회원을 대리하지 않습니다. 회원
      상호간에 성립된 거래와 회원이 제공한 정보에 대한 책임은 해당 회원이 직접
      부담해야 합니다.
    </p>
    <p>
      ② 회사는 판매자가 등록한 상품 및 서비스의 내용과 거래조건에 대해서 어떠한
      보증이나 대리를 하지 않으며, 회원은 전적으로 본인의 책임하에 상품 등을
      구매해야 합니다.
    </p>
    <p>
      ③ 회사는 리뷰어가 게시한 리뷰 즉 상품 및 서비스의 내용과 정보 및 평가에
      대해서 어떠한 보증이나 대리를 하지 않으며, 회원은 전적으로 본인의 책임하에
      상품 등을 구매해야 합니다.
    </p>
    <p>
      ④ 회사는 회원 상호간의 모든 행위(거래행위 포함)와 관련하여 판매의사 또는
      구매의사의 여부 및 진정성, 등록 상품의 품질, 완전성, 안정성, 적법성 및
      타인의 권리에 대한 비침해성, 회원이 입력하는 정보 및 그 정보를 통하여
      연결된 웹사이트 자료의 진실성 또는 적법성 등 일체에 대하여 보증하지
      않으며, 이와 관련된 일체의 위험과 책임은 해당 당사자들이 부담합니다.
    </p>
    <p>
      ⑤ 회사는 회원의 귀책사유로 서비스 이용에 장애가 발생하는 것에 대하여
      책임을 지지 않습니다.
    </p>
    <p>
      ⑥ 회사는 회원이 본인의 개인정보를 타인에게 유출하거나 제공하여 발생하는
      피해에 대해 책임을 지지 않습니다.
    </p>
    <p>
      ⑦ 상품의 매매와 관련하여 발생하는 상품의 배송, 청약철회, 반품 등의
      거래진행은 거래 당사자인 판매자와 구매자 간에 수행되어야 하며, 회사는
      거래진행에 관여하지도 책임지지도 않습니다.
    </p>
    <p>
      ⑧ 회사가 제5조 ②항의 사유로 서비스를 일시적으로 중단해야 할 경우, 이는
      서비스 일시 중단이 불가피하거나 불가항력적인 경우이므로 이용자 또는
      제3자에게 손해가 발생했더라도 책임을 부담하지 않습니다.
    </p>
    <p>
      ⑨ 회사의 서비스 화면에서 링크, 배너 등을 통해 연결된 회사(이하
      피연결회사)와 이용자 간에 이루어진 거래에 회사는 개입하지 않으며, 해당
      거래에 대하여 책임을 지지 않습니다.
    </p>
    <br />

    <h5>제3장 기타사항</h5>

    <h6>제26조 (연결몰과 피연결몰의 관계)</h6>

    <p>
      ① 상위 사이버몰과 하위 사이버몰이 하이퍼링크(예: 하이퍼링크의 대상에는
      문자, 그림 및 동화상 등이 포함)방식 등으로 연결된 경우, 전자를
      연결몰이라고 하고, 후자를 피연결몰이라고 합니다.
    </p>
    <p>
      ② 회사의 제휴사가 피연결몰이고 해당 피연결몰의 상품/서비스가 램 플랫폼에도
      등록되어 있으며, 소비자가 램 플랫폼에 등록된 상품/서비스를 클릭해
      피연결몰로 이동, 해당 상품/서비스를 구매하는 경우, 회사는 제휴사와 구매자
      간의 거래에 대해 제2장 해당 사항을 보증합니다. 단, 회사의 제휴사가
      피연결몰이라 할지라도, 램 플랫폼을 통해 거래되지 않은 상품/서비스에 대해
      회사는 일절 보증책임을 지지 않습니다.
    </p>
    <p>
      ③ 램 플랫폼에 게시된 광고가 피연결몰로 연결되는 경우, 회사는 해당
      피연결몰에서 일어나는 판매자와 구매자 간 거래에 대해 일절 보증책임을 지지
      않습니다.
    </p>
    <br />

    <h6>제27조 (분쟁해결)</h6>

    <p>
      ① 회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상
      처리하기 위해서 피해보상처리기구를 설치·운영합니다.
    </p>
    <p>
      ② 회사는 회원으로부터 제출되는 불만사항 및 의견을 우선해서 처리합니다.
      다만, 신속한 처리가 곤란한 경우에는 회원에게 그 사유와 처리 일정을
      통보합니다.
    </p>
    <br />

    <h6>제28조 (재판권 및 준거법)</h6>

    <p>
      ① 본 약관의 해석 및 회사와 회원 간의 분쟁에 대하여는 대한민국의 법을
      적용합니다.
    </p>
    <p>
      ② 이 약관 및 서비스 이용과 관련해 회사와 회원 사이에 분쟁이 발생하여
      소송이 제기되는 경우에는 주식회사 리뷰앤메이크머니의 소재지에 있는
      관할법원으로 합니다.
    </p>
    <br />

    <h6>부칙</h6>

    <p>본 약관은 2022년 09월 08일부터 시행합니다.</p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
